<template>
    <v-data-table :headers="headers"
                  :items="items"
                  disable-pagination
                  dense
                  class="ma-0 elevation-1"
                  hide-default-footer
                  item-key="campo">
        <template v-slot:item.nombre="props">
            <v-edit-dialog :return-value.sync="props.item.nombre"
                           large
                           persistent>
                <div>{{ props.item.nombre }}</div>
                
                <template v-slot:input>
                    <div class="mt-4 title">actualiza descripción</div> 
                </template>
        
                <template v-slot:input>
                    <v-text-field v-model="props.item.nombre"
                                  :rules="[max25chars]"
                                  label="Descripción"
                                  single-line
                                  counter
                                  autofocus></v-text-field>
                </template>
            </v-edit-dialog>
        </template>

        <template v-slot:item.visible="{ item }">
            <v-simple-checkbox v-model="item.visible"></v-simple-checkbox>
        </template>

        <template v-slot:item.obligatorio="{ item }">
            <v-simple-checkbox v-model="item.obligatorio"></v-simple-checkbox>
        </template>
    </v-data-table>
</template>
<script>
export default {
    name: 'FieldProperties',

    props: ['items'],

        data: () => ({
            max25chars: v => v.length <= 25 || 'Muy largo!',

        headers: [
            { text: 'Visible', value: 'visible' },
            { text: 'Obligatorio', value: 'obligatorio' },
            { text: 'Descripcion', value: 'nombre' },
            { text: 'Campo', value: 'campo', sortable: false }
        ]
        }),
};
</script>
