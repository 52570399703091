<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="cancela">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="graba">Guarda</v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row justify="space-around">
                    <v-layout wrap>
                        <v-flex xs12 sm4>
                            <v-text-field v-model="item.nombre" label="Nombre">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm4>
                            <selectEstado label="Estado al iniciar"
                                          v-model="item.estadoInicial.id"
                                          :texto="item.estadoInicial.nombre"
                                          @texto="item.estadoInicial.nombre = $event">
                            </selectEstado>
                        </v-flex>

                        <v-flex xs12 sm4>
                            <selectEstado label="Estado al finalizar"
                                          v-model="item.estadoFinal.id"
                                          :texto="item.estadoFinal.nombre"
                                          @texto="item.estadoFinal.nombre = $event">
                            </selectEstado>
                        </v-flex>

                        <v-flex xs1>
                            <v-btn class="ma-1"
                                   fab
                                   color="yellow"
                                   @click="dialogoIconos = true">
                                <v-icon dark>{{ item.icono }}</v-icon>
                            </v-btn>
                        </v-flex>

                        <v-flex xs11>
                            <selectModulo v-model="item.modulo" />
                        </v-flex>

                        <v-flex pt-0
                                pb-0
                                sm12
                                v-if="getGrant('0501')">
                            <fieldProperties :items="item.tramiteCampo">
                            </fieldProperties>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
            <template>
                <v-dialog v-model="dialogoIconos" max-width="800px" persistent>
                    <iconos v-model="item.icono"
                            @close="dialogoIconos = false"></iconos>
                </v-dialog>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import fieldProperties from '@/components/Tools/FieldProperties';
    import iconos from '@/components/Tools/Iconos';
    import selectEstado from '@/components/Selects/selectEstado';
    import selectModulo from '@/components/Selects/selectModulo';
    export default {
        name: 'tramiteDialogo',
        data() {
            return {
                dialogoIconos: false
            };
        },
        computed: {
            ...mapState('tramite', ['item']),
            ...mapState('account', ['permisos', 'loggedUser']),

            formTitle() {
                return this.id == 0 ? 'Nuevo tramite' : 'Edita tramite';
            },

            icono() {
                return 'mdi-plus';
            }
        },

        methods: {
            ...mapActions('tramite', ['closeDialog', 'saveObject']),

            graba() {
                this.saveObject();
                this.cancela();
            },

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            cancela() {
                this.closeDialog();
            }
        },

        components: {
            fieldProperties,
            iconos,
            selectEstado,
            selectModulo
        }
    };
</script>
