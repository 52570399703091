var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"dense":"","loading":_vm.progress,"items":_vm.items,"sort-by":"orden","hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.icono",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icono))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"yellow"}},[_c('v-toolbar-title',[_vm._v("Tramite")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"900px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"warning"},on:{"click":function($event){return _vm.editItem(null)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.propDialog),callback:function ($$v) {_vm.propDialog=$$v},expression:"propDialog"}},[_c('tramiteDialogo')],1)],1)]},proxy:true},{key:"item.modulo",fn:function(ref){
var item = ref.item;
return [(item.modulo & 1)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle-outline")]),(item.modulo & 2)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle-outline")]),(item.modulo & 4)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle-outline")]),(item.modulo & 8)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle-outline")]),(item.modulo & 16)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle-outline")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])]}},{key:"item.posicion",fn:function(ref){
var item = ref.item;
return [_c('v-flex',{attrs:{"xs11":""}},[_c('ArribaAbajo',{attrs:{"max":_vm.maxItems},on:{"change":function($event){return _vm.change(item, $event)}},model:{value:(item.orden),callback:function ($$v) {_vm.$set(item, "orden", $$v)},expression:"item.orden"}})],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"yellow"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }