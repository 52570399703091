<template>
    <tramiteCrud></tramiteCrud>
</template>

<script>
import tramiteCrud from '@/components/Tramite/TramiteCrud';

export default {
    name: 'tramite',
    data() {
        return {};
    },
    components: {
        tramiteCrud
    }
};
</script>
