<template>
    <v-data-table :headers="headers"
                  dense
                  :loading="progress"
                  :items="items"
                  sort-by="orden"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                  @click:row="editItem">

        <template v-slot:item.icono="{ item }">
            <v-icon >{{item.icono}}</v-icon>
        </template>

        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Tramite</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="propDialog" max-width="900px" persistent>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-2"
                               fab
                               dark
                               x-small
                               color="warning"
                               @click="editItem(null)">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <tramiteDialogo />
                </v-dialog>
            </v-toolbar>
        </template>

        <template v-slot:item.modulo="{ item }">
            <v-icon v-if="item.modulo & 1" small>mdi-checkbox-marked-circle</v-icon>
            <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>

            <v-icon v-if="item.modulo & 2" small>mdi-checkbox-marked-circle</v-icon>
            <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>

            <v-icon v-if="item.modulo & 4" small>mdi-checkbox-marked-circle</v-icon>
            <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>

            <v-icon v-if="item.modulo & 8" small>mdi-checkbox-marked-circle</v-icon>
            <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>

            <v-icon v-if="item.modulo & 16" small>mdi-checkbox-marked-circle</v-icon>
            <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>
        </template>

        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                delete
            </v-icon>
        </template>

        <template v-slot:item.posicion="{ item }">
            <v-flex xs11>
                <ArribaAbajo v-model="item.orden"
                             :max="maxItems"
                             @change="change(item, $event)">
                </ArribaAbajo>
            </v-flex>
        </template>

        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ArribaAbajo from '@/components/Tools/ArribaAbajo';
import tramiteDialogo from '@/components/Tramite/TramiteDialogo';

export default {
    name: 'tramiteCrud',
    data() {
        return {
            headers: [
                { text: 'Icono', value: 'icono' },
                //{ text: 'Id', align: 'left', sortable: true, value: 'id' },
                {
                    text: 'Orden',
                    align: 'right',
                    sortable: true,
                    value: 'orden'
                },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Estado al Inicio', value: 'estadoInicial.nombre' },
                { text: 'Estado al Finalizar', value: 'estadoFinal.nombre' },
                { text: 'Posicion', value: 'posicion' },
                { text: 'Módulos', value: 'modulo' },
                { text: 'Opciones', value: 'action', sortable: false }
            ]
        };
    },

    computed: {
        ...mapState('tramite', [
            'items',
            'item',
            'dialog',
            'borraDialog',
            'progress'
        ]),

        propDialog: {
            get() {
                return this.dialog;
            },
            set(value) {
                this.setDialog(value);
            }
        },

        maxItems() {
            return this.items.length;
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        ...mapActions('tramite', [
            'initialize',
            'editItem',
            'borraItem',
            'deleteObject',
            'closeBorraDialog',
            'setDialog',
            'changePosition'
        ]),

        change(item, inc) {
            this.changePosition({ item, inc });
        },

        deleteItem(item) {
            const index = this.items.indexOf(item);
            if (confirm('Desea borrar el tramite?')) {
                this.deleteObject(item.id);
                this.items.splice(index, 1);
            }
        }
    },
    components: {
        ArribaAbajo,
        tramiteDialogo
    }
};
</script>
